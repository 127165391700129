@import-normalize;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html,
body {
  display: flex;
  flex-direction: column;

  min-height: 100vh;
  margin: 0;
}

#root {
  display: flex;
  flex-direction: column;
  flex: 1;
}

main {
  flex: 1 0 auto;
}

a {
  text-decoration: none;
  font-style: inherit;
  color: inherit;
}

input {
  padding: 0;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}
ul {
  margin: 0;
  padding-left: 0;
  list-style: none;
}
